canvas {
    display: block;
    margin: 0 auto; /* Center the canvas horizontally */
}

/* When the screen height is smaller than the width */
@media (max-aspect-ratio: 1/1) {
    canvas {
        width: 80vw;
        height: 80vw;
    }
}

/* When the screen width is smaller than the height */
@media (min-aspect-ratio: 1/1) {
    canvas {
        width: 80vh;
        height: 80vh;
    }
}