html,
body {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: #8e7dbe;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;

  background-size: cover;
  background-attachment: fixed;
  color: #f1e3d3;

  --cursor-color: #99c1b9;
}
